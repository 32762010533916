import Head from "next/head";
import React, { useEffect, useState } from "react";
import axios from "axios";
import ReqDemoModal from "../components/reqDemo";
import { useDisclosure } from "@chakra-ui/react";
import { useRouter } from "next/router";
import ContactModal from "../components/contactModal";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Icon,
  useColorModeValue,
  createIcon,
  SimpleGrid,
  Flex,
  Avatar,
  List,
  ListItem,
  ListIcon,
  VStack,
  Center,
  HStack,
  Input,
  Stat,
  StatLabel,
  StatNumber,
  chakra,
  Link,
  Grid,
  IconButton,
} from "@chakra-ui/react";

import Image from "next/image";
import { BsPerson } from "react-icons/bs";
import { FiServer } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { MdWorkOutline } from "react-icons/md";
import Footer from "../components/footer";
import aicover from "../public/assets/AiCoverimage.png";
import Chatbot from "./chatbot";
import Availability_img from "../public/assets/availability_img1.png";
import Biasfree_img from "../public/assets/Biasfree_img1.png";
import Carbonreduction_img from "../public/assets/carbonreduction_img1.png";
import Costreduction_img from "../public/assets/Costreduction_img1.png";
import Fakecandid_img from "../public/assets/Fakecandid_img1.png";
import Proctored_img from "../public/assets/proctored_img1.png";
import comprehensive_img from "../public/assets/comprehensive_img1.png";

const Feature = ({ title, text, image }) => {
  return (
    <Flex direction="column" alignItems="center">
      <Center w={16} h={16} rounded="full" bg="gray.100" mb={1}>
        {image}
      </Center>
      <Text fontWeight={600} textAlign="center">
        {title}
      </Text>
      <Text color="gray.600" textAlign="center">
        {text}
      </Text>
    </Flex>
  );
};

function StatsCard(props) {
  const { title, stat, icon } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={"5"}
      shadow={"xl"}
      border={"1px solid"}
      borderColor={useColorModeValue("gray.800", "gray.500")}
      rounded={"lg"}
    >
      <Flex justifyContent={"space-between"}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={"medium"} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          my={"auto"}
          color={useColorModeValue("gray.800", "gray.200")}
          alignContent={"center"}
        >
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}
export default function Home() {
  const [totalUser, setTotalUser] = useState(0);
  const [assessmentUser, setAssessmentUser] = useState(0);
  const [jobsPosted, setJobsPosted] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeIndex, setActiveIndex] = useState(1);

  const [isModalOpen, setModalOpen] = useState(false);
  const router = useRouter();

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleSeeDetailsClick = (type) => {
    setModalOpen(type);
  };

  useEffect(() => {
    // Fetch data for "Total users" from your backend API
    axios
      .get("https://161.97.173.8:8000/get_total_users", {
        responseType: "json",
      })
      .then((response) => {
        const data = response.data;
        console.log("API Response for Total Users:", data);

        setTotalUser(data.total_users_count || 0);
      })
      .catch((error) => {
        console.error("Error fetching Total users:", error);
      });

    // Fetch data for "Assessments users" from your backend API
    axios
      .get("https://161.97.173.8:8000/count_Assessed_Professionals", {
        responseType: "json",
      })
      .then((response) => {
        const data = response.data;
        console.log("API Response for Total Assessments:", data);
        setAssessmentUser(data.user_id_count || 0);
      })
      .catch((error) => {
        console.error("Error fetching Trusted By:", error);
      });

    // Fetch data for "Jobs Posted" from your backend API
    axios
      .get("https://161.97.173.8:8000/total_job", {
        responseType: "json",
      })
      .then((response) => {
        const data = response.data;
        const totalJobs = data.total_jobs;
        console.log("API Response for Jobs Posted:", totalJobs);
        setJobsPosted(totalJobs || 0);
      })
      .catch((error) => {
        console.error("Error fetching Jobs Posted:", error);
      });
  }, []);

  <Head>
    <title>Aptofy | AI based recruitment </title>

    <link rel="canonical" href="https://aptofy.com/" key="canonical" />
    <link rel="canonical" href="https://www.aptofy.com/" key="canonical" />

    <link
      href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap"
      rel="stylesheet"
    />
    <meta title="Aptofy | Best AI-Based Recruitment Company in India"></meta>
    <meta
      content="Discover the power of Aptofy, India's best AI-based recruitment company. 
          Streamline your hiring process with advanced technology and comprehensive solutions 
          tailored to your need"
      name="description"
    />
    {/* <meta
          content="Aptofy, 
                           recruitment solution,
                           recruit,
                           hire,
                           Ai based recruitment,
                           ai based job portal,
                           ai based recruitment platform,
                           aldi how to get hired,
                           bias employment,
                           bias-free hiring,
                           executive job search sites,
                           aptofy jobs,
                           aptofy ai recruitment,
                           aptofy ,
                           AI powered recruitment,
                           fast closure aptofy,
                           aptofy training,
                           aptofy test,
                           aptofy visualized test report,
                           aptofy candidate sourcing,
                           aptofy candidate evaluation,
                           aptofy ai candidate screening "
          name="keywords"
        /> */}

    <meta name="rating" CONTENT="General" />
    <meta content="all" name="Googlebot-Image" />
    <meta content="all" name="Slurp" />
    <meta content="all" name="Scooter" />
    <meta content="ALL" name="WEBCRAWLERS" />
    <meta name="revisit-after" CONTENT="2 days" />
    <meta name="robots" content=" ALL, index, follow" />
    <meta name="robots" content=" ALL, index, follow" />
    <meta name="distribution" content="Global" />
    <meta name="rating" content="Safe For All" />
    <meta name="language" content="English" />
    <meta http-equiv="window-target" content="_top" />
    <meta http-equiv="pics-label" content="for all ages" />
    <meta name="rating" content="general" />
    <meta content="All, FOLLOW" name="GOOGLEBOTS" />
    <meta content="All, FOLLOW" name="YAHOOBOTS" />
    <meta content="All, FOLLOW" name="MSNBOTS" />
    <meta content="All, FOLLOW" name="BINGBOTS" />

    {/* header icon  */}
    <link
      rel="icon"
      type="image/png"
      sizes="180x180"
      href="/assets/aptofy.png"
    />
    <link rel="icon" type="image/png" sizes="32x32" href="/assets/aptofy.svg" />
    <link rel="apple-touch-icon" sizes="180x180" href="/assets/aptofy.png" />
    <link rel="mask-icon" href="/static/favicon-mask.svg" color="#000000" />
  </Head>;

  const testimonials = [
    {
      id: 1,
      text: "Aptofy helps us connect and select top talent effortlessly. Recruitment is no longer a burden—it's smooth and efficient!",
    },
    {
      id: 2,
      text: "With Aptofy, we've seamlessly connected with exceptional candidates worldwide. The flexibility to conduct interviews anytime has streamlined our hiring, making it convenient and more efficient.",
    },
    {
      id: 3,
      text: "Aptofy made my interview process remarkably simple! With fewer tech rounds and a hassle-free experience. I felt supported and confident throughout and was thrilled to be selected.",
    },
  ];

  const handleNext = () => {
    setActiveIndex((prev) => (prev === testimonials.length - 1 ? 0 : prev + 1));
  };

  const handlePrev = () => {
    setActiveIndex((prev) => (prev === 0 ? testimonials.length - 1 : prev - 1));
  };

  return (
    <>
      <Chatbot />
      <Flex
        direction="column"
        align="center"
        py={{ base: 12, md: 24 }}
        px={8}
        w="100%"
      >
        {/* Main Heading */}
        <Heading
          color="#3A56D4"
          fontWeight={700}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
          textAlign="center"
          mb={8}
        >
          Aptofy: The Cost-Effective AI Bot Revolutionizing Talent Acquisition
        </Heading>

        {/* Image and Text Section */}
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          align="center"
          w="100%"
          maxW="1200px"
          gap={10}
          px={{ base: 4, md: 0 }}
          mb={16}
        >
          {/* Image on Left */}
          <Box
            flexShrink={0}
            flex={{ base: "1", md: "1 1 50%" }}
            borderRadius="lg"
            overflow="hidden"
            boxShadow="lg"
          >
            <Image src={aicover} width="100%" height="auto" borderRadius="lg" />
          </Box>

          {/* Text on Right */}
          <Box
            flex={{ base: "1", md: "1 1 50%" }}
            textAlign={{ base: "center", md: "left" }}
          >
            <Text fontSize={{ base: "lg", md: "xl" }} mb={6}>
              Discover top talent like never before with Aptofy, the
              cutting-edge AI-powered recruitment solution. Aptofy goes beyond
              traditional candidate screening and evaluation to revolutionize
              the hiring process from start to finish.
            </Text>
            {/* Request Demo Button */}
            <Button
              onClick={onOpen}
              color="white"
              bg="#3B58D7"
              size="lg"
              py={6}
              px={10}
              mb={12}
              borderRadius="full"
              _hover={{
                bg: "#2A46B0",
              }}
            >
              Request Demo
            </Button>
          </Box>
        </Flex>

        {/* The Aptofy Edge Section */}
        <Box
          maxW="1400px"
          w="100%"
          textAlign="center"
          mb={12 /* Reduced from 16 */}
        >
          <Heading
            size="lg"
            color="#3A56D4"
            fontWeight={600}
            mb={6 /* Reduced from 8 */}
          >
            The Aptofy Edge
          </Heading>

          <Grid
            templateColumns={{
              base: "repeat(2, 1fr)",
              sm: "repeat(3, 1fr)",
              md: "repeat(4, 1fr)",
            }}
            gap={6 /* Reduced from 8 */}
            justifyItems="center"
          >
            {[
              { img: Availability_img, title: "Availability 300%" },
              { img: Biasfree_img, title: "Bias-Free 100%" },
              { img: Carbonreduction_img, title: "Carbon Footprint Reduction 30%" },
              { img: Costreduction_img, title: "Cost Reduction 33%" },
              { img: Fakecandid_img, title: "Fake Candidate Detection 99%" },
              { img: Proctored_img, title: "Proctored 100%" },
              { img: comprehensive_img, title: "Comprehensive Evaluation 99% Accuracy" },
            ].map((item, idx) => (
              <Box
                key={idx}
                maxW="200px"
                p={3 /* Reduced from 4 */}
                borderRadius="lg"
                boxShadow="lg"
                textAlign="center"
                overflow="hidden"
                transition="transform 0.3s ease, box-shadow 0.3s ease"
                _hover={{
                  transform: "scale(1.05)",
                  boxShadow: "xl",
                }}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="280px" /* Reduced from 300px */
              >
                {/* Image Section */}
                <Box
                  flex="1"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={2 /* Reduced from 4 */}
                >
                  <Image
                    src={item.img}
                    alt={item.title}
                    borderRadius="lg"
                    maxHeight="140px" /* Reduced from 150px */
                    objectFit="contain"
                  />
                </Box>

                {/* Title Section */}
                <Text
                  mt={2 /* Reduced from 4 */}
                  fontSize="sm"
                  /* Reduced font size */ fontWeight="bold"
                >
                  {item.title}
                </Text>
              </Box>
            ))}

            {/* Add "Learn More" Button in Grid */}
            <Box
              maxW="200px"
              p={3 /* Reduced from 4 */}
              overflow="hidden"
              display="flex"
              alignItems="center"
              justifyContent="center"
              transition="transform 0.3s ease, box-shadow 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
              }}
            >
              <Link href="/AboutUs">
                <Button
                  bg="#3A56D4"
                  _hover={{ bg: "#2A46B0" }}
                  color="white"
                  size="sm" /* Reduced from md */
                >
                  Learn More
                </Button>
              </Link>
            </Box>
          </Grid>
        </Box>

        {/* Request Demo Modal */}
        <ReqDemoModal isOpen={isOpen} onClose={onClose} />
      </Flex>

      {/* What We Offer */}
      <Box p={2}>
        {" "}
        <Center>
          <Heading
            as="h1"
            fontSize={{ base: "24px", md: "28px" }}
            textAlign="center"
            color="#3A56D4"
            // textDecoration="underline"
          >
            The Aptofy Offerings
          </Heading>
        </Center>
        <Text textAlign="center" mt={4}>
          {" "}
          Unlock your organisation's true potential with Aptofy, the AI-powered
          recruitment solution that revolutionises the way you find, assess, and
          hire top talent. Say goodbye to tedious processes and welcome an
          efficient, accurate, and reliable solution that takes your recruitment
          strategy to the next level.
        </Text>
      </Box>

      <SimpleGrid
        columns={[1, null, 2]}
        spacing="40px"
        p="20px"
        justifyItems="center"
      >
        {/* Box 1 */}
        <Box
          // fontFamily="Arial, sans-serif"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          p="20px"
          // background="linear-gradient(to right, #f5f5f5, #ffffff)"
          boxShadow="md"
          textAlign="left" // Align text to left for better readability
        >
          <Text
            as="h3"
            size="lg"
            mb="10px"
            color="#3A56D4"
            // fontFamily="Helvetica"
          >
            Discover Your Ideal Talent with Aptofy
          </Text>
          <Text mb="20px">
            Aptofy is your dedicated Talent Acquisition Specialist, meticulously
            screening candidates and delivering comprehensive evaluations. With
            Aptofy, obtaining high-quality candidate profiles that perfectly
            match your requirements is no longer a dream but a reality.
          </Text>
          <Text mb="20px">
            Our AI-driven bot conducts interviews for you, ensuring that every
            assessment is tailored to your specific needs. We provide detailed
            reports on verbal, non-verbal, and written communication skills.
            Make informed decisions, build exceptional teams, and fulfill your
            requirements with confidence.
          </Text>
          <Text mb="20px">
            Experience the future of talent acquisition with Aptofy Today!
          </Text>

          <Button
            color={"white"}
            bg="#3B58D7"
            onClick={() => handleSeeDetailsClick(1)}
            _hover={{
              bg: "#2A46B0",
            }}
          >
            See Details
          </Button>
        </Box>

        {/* Box 2 */}
        <Box
          // fontFamily="Arial, sans-serif"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          p="20px"
          // background="linear-gradient(to right, #ffffff, #f5f5f5)"
          boxShadow="md"
          textAlign="left" // Align text to left for better readability
        >
          <Text as="h3" size="lg" mb="10px" color="#3A56D4">
            Interview as a Service with Aptofy - The AI bot
          </Text>
          <Text
            mb="20px"
            //  fontFamily="Merriweather Sans"
          >
            Aptofy is your expert ally, conducting interviews and providing
            comprehensive evaluations. Assess your candidates with accuracy,
            consistency, and speed, all while eliminating bias.
          </Text>
          <Text mb="20px">
            Aptofy evaluates candidates using a range of objective questions,
            coding challenges, and interview scenarios. We assess situational
            and problem-solving abilities, as well as communication and other
            essential soft skills.
          </Text>
          <Text mb="20px">
            Discover the power of precise, fair, and efficient candidate
            assessments with Aptofy.
          </Text>
          <Text mb="20px">
            We specialize in niche domains, offering customizable evaluations
            tailored to fit your organization's unique needs.
          </Text>
          <Button
            color={"white"}
            bg="#3B58D7"
            onClick={() => handleSeeDetailsClick(2)}
            _hover={{
              bg: "#2A46B0",
            }}
          >
            See Details
          </Button>
        </Box>

        {/* Box 3 */}
        <Box
          // fontFamily="Arial, sans-serif"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          p="20px"
          gridColumn={["1", null, "span 2"]}
          // background="linear-gradient(to bottom, #ffffff, #f5f5f5)"
          boxShadow="md"
          maxWidth="635px"
          width="100%"
          textAlign="left" // Align text to left for better readability
        >
          <Text as="h3" size="lg" mb="10px" color="#3A56D4">
            Elevate Your Training Institute with AI-Powered Proctored
            Assessments
          </Text>
          <Text mb="20px">
            Transform your training institute with our AI-powered proctored
            assessments, designed for secure, efficient, and flexible
            evaluations. Our advanced AI technology handles and monitors
            assessments, freeing trainers from invigilation, checking, and
            allowing automatic certificate generation upon completion. Trainees
            enjoy the flexibility of phygital training, taking exams at their
            convenience. Aptofy’s training interviews and global placement
            assistance boost employability, making your local institute a global
            contender. This solution streamlines operations, reduces
            administrative tasks, and supports growth, helping your institute
            reach new heights. Embrace the future of training with our
            cutting-edge technology and revolutionize your certification process
            today.
          </Text>
          <Text mb="20px">
            We are easy to integrate, secure, and protect your intellectual
            property. We help you brand yourself.
          </Text>
          <Button
            color={"white"}
            bg="#3B58D7"
            onClick={() => handleSeeDetailsClick(3)}
            _hover={{
              bg: "#2A46B0",
            }}
          >
            See Details
          </Button>
        </Box>
      </SimpleGrid>

      <Box
        maxW="7xl"
        mx="auto"
        pt={5}
        px={{ base: 2, sm: 12, md: 17 }}
        mt={4} /* Reduced top margin */
      >
        {/* Heading */}
        <Heading
          textAlign="center"
          fontSize="4xl"
          py={4} /* Reduced padding-y for a tighter layout */
          fontWeight="bold"
          color="#3B58D7"
        >
          The Aptofy Experience
        </Heading>

        {/* Carousel Container */}
        <Box position="relative" h="400px" overflow="hidden" mt={2}>
          {" "}
          {/* Reduced top margin */}
          <Flex justify="center" align="center" h="full">
            {testimonials.map((testimonial, index) => {
              let position = index - activeIndex;
              if (position < 0) position += testimonials.length;

              return (
                <Box
                  key={testimonial.id}
                  position="absolute"
                  transition="all 0.5s ease-in-out"
                  transform={`translateX(${
                    position === 0 ? "0%" : position === 1 ? "100%" : "-100%"
                  }) scale(${index === activeIndex ? "1" : "0.8"})`}
                  opacity={index === activeIndex ? 1 : 0.5}
                  zIndex={index === activeIndex ? 2 : 1}
                  width={{ base: "90%", md: "500px" }}
                  textAlign="center"
                  px={{ base: 4, sm: 8 }}
                  py={6}
                  border="1px solid #ccc"
                  borderRadius="md"
                  boxShadow="lg"
                >
                  <Text fontSize="lg" fontStyle="italic">
                    "{testimonial.text}"
                  </Text>
                </Box>
              );
            })}
          </Flex>
        </Box>

        {/* Navigation Buttons */}
        <Flex justify="center" mt={1} gap={2}>
          {" "}
          {/* Reduced top margin */}
          <IconButton
            onClick={handlePrev}
            aria-label="Previous testimonial"
            icon={<ChevronLeftIcon />}
            colorScheme="blue"
            size="md"
            variant="outline"
          />
          <IconButton
            onClick={handleNext}
            aria-label="Next testimonial"
            icon={<ChevronRightIcon />}
            colorScheme="blue"
            size="md"
            variant="outline"
          />
        </Flex>
      </Box>

      <Box
        maxW="7xl"
        mx={"auto"}
        pt={5}
        px={{ base: 2, sm: 12, md: 17 }}
        mt={50}
      >
        <Heading
          // chakra.h1
          textAlign={"center"}
          fontSize={"4xl"}
          py={10}
          fontWeight={"bold"}
          color="#3B58D7"
        >
          Join the growth wave! Amplify your company's success with Aptofy
        </Heading>
        {/* <Text textAlign={'center'} fontSize={'xl'} py={2}>
      instead of Users  - Trusted By , Assessed Professionals , countries
      </Text><br/> */}
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
          <StatsCard
            title={"Trusted By"}
            stat={totalUser}
            icon={<BsPerson size={"3em"} />}
          />
          <StatsCard
            title={"Assessed Professionals"}
            stat={assessmentUser}
            icon={<FiServer size={"3em"} />}
          />
          <StatsCard
            title={"Opportunities"}
            stat={jobsPosted}
            icon={<MdWorkOutline size={"3em"} />}
          />
        </SimpleGrid>
      </Box>
      <Box mt={20}></Box>
      <Footer />
      {/* Contact Modal */}
      <ContactModal
        isOpen={isModalOpen}
        onClose={closeModal}
        type={isModalOpen}
      />
    </>
  );
}
